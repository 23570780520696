import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import ChatRow from "./ChatRow";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { SendIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";

const PLACEHOLDER_EVENTS = [
  {
    type: "NOTIF",
    participant: `nickname`,
    chatStatus: `ENDED`,
  },
  {
    type: "NOTIF",
    participant: `nickname`,
    chatStatus: `STARTED`,
  },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#68D773",
    authorType: "Mod",
    text: `I'll send you a request instead.`,
  },
  {
    type: "MESSAGE",
    author: "Biffchain",
    color: "#B8564D",
    authorType: "All",
    text: `Okay sir, thank you..`,
  },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#71CCFF",
    authorType: "Mod",
    text: `I'll find out.`,
  },
  {
    type: "MESSAGE",
    author: "Michael Great",
    color: "#FFDE69",
    authorType: "All",
    text: `Okay...Count as forfeit next time.`,
  },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#68D773",
    authorType: "Mod",
    text: `I'll send you a request instead.`,
  },
  {
    type: "MESSAGE",
    author: "Biffchain",
    color: "#B8564D",
    authorType: "All",
    text: `Okay sir, thank you..`,
  },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#71CCFF",
    authorType: "Mod",
    text: `I'll find out.`,
  },
  {
    type: "MESSAGE",
    author: "Michael Great",
    color: "#FFDE69",
    authorType: "All",
    text: `Okay...Count as forfeit next time.`,
  },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#68D773",
    authorType: "Mod",
    text: `I'll send you a request instead.`,
  },
  {
    type: "MESSAGE",
    author: "Biffchain",
    color: "#B8564D",
    authorType: "All",
    text: `Okay sir, thank you..`,
  },
  // {
  //   type: "NOTIF",
  //   participant: `nickname`,
  //   chatStatus: `ENDED`
  // },
  // {
  //   type: "NOTIF",
  //   participant: `nickname`,
  //   chatStatus: `STARTED`
  // },
  {
    type: "MESSAGE",
    author: "Benito",
    color: "#71CCFF",
    authorType: "Mod",
    text: `I'll find out.`,
  },
  {
    type: "MESSAGE",
    author: "Michael Great",
    color: "#FFDE69",
    authorType: "All",
    text: `Okay...Count as forfeit next time.`,
  },
];
const Chatbox = ({ closeChatbox, poolData = {}, roomId, roomChats = [] }) => {
  const chatboxRef = useRef(null);
  const [userInput, setUserInput] = useState("");
  const { socket } = useContext(SocketContext);

  useOnClickOutside(chatboxRef, () => {
    if (closeChatbox) {
      closeChatbox();
    }
  });

  const inputRef = useRef();
  const scrollToRef = useRef();

  useEffect(() => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [roomChats]);

  // Scrolling to last message when first visiting the section
  useEffect(() => {
    if (scrollToRef && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  function sendMessage() {
    socket.emit("sendRoomMessage", {
      type: "text",
      value: userInput,
      roomId: roomId,
    });
    setUserInput("");
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleUserInput(e) {
    setUserInput(e.target?.value);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      sendMessage();
    }
  }

  return (
    <S.ChatBox ref={chatboxRef}>
      <S.ChatBoxHeader>
        <S.Flex>
          {/* TODO: Show game image below */}
          {/* <MessagesSquareIcon />  */}
          <S.ChatHeaderImg src={poolData?.assets?.gameThumbnail} />
          <S.HeaderTextContainer>
            <div>{poolData?.name}</div>
            <small>ID: {poolData?.id}</small>
          </S.HeaderTextContainer>
        </S.Flex>
      </S.ChatBoxHeader>
      <S.ChatMessagesContainer>
        {roomChats.map(chat => (
          <ChatRow chat={chat} />
        ))}
        <div style={{ visibility: "hidden" }} ref={scrollToRef} />
      </S.ChatMessagesContainer>
      <S.InputContainer>
        <S.ChatInput ref={inputRef} value={userInput} onKeyDown={handleKeyDown} onChange={handleUserInput} />
        <S.SendButton onClick={sendMessage}>
          <SendIcon />
        </S.SendButton>
      </S.InputContainer>
    </S.ChatBox>
  );
};

export default Chatbox;
