import React from "react";
import * as S from "./styles";

const ChatRow = ({ chat }) => {
  const {
    message = {},
    userNick,
    userColor = "#FFF",
    authorType = "",
    // participant,
    // chatStatus = "STARTED",
  } = chat;
  if (message.type === "text")
    return (
      <S.MessageRow color={userColor}>
        <span>{userNick}:</span>
        {message.value}
      </S.MessageRow>
    );

  // if (message.type === `NOTIF`)
  //   return (
  //     <S.NotifRow chatStatus={chatStatus}>
  //       {chatStatus === `STARTED`
  //         ? `You're now chatting with ${participant}`
  //         : `Your chat with ${participant} has ended`}
  //     </S.NotifRow>
  //   );

  return <></>;
};

export default ChatRow;
