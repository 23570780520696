import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import Chatbox from "./Chatbox";
import { MessagesIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";

const Chat = ({ poolData, matchId }) => {
  const [showChatbox, setShowChatbox] = useState(false);
  const [roomChats, setRoomChats] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const { socket } = useContext(SocketContext);

  function toggleChatbox() {
    setShowChatbox(prev => !prev);
  }
  function closeChatbox() {
    setShowChatbox(false);
  }

  const roomId = `${poolData.id}_${matchId}`;

  function handleMessageReceived(data) {
    console.log("LOGGER - Socket Message Received: ", data, showChatbox);
    setRoomChats(prev => [...prev, data]);
    if (!showChatboxRef.current) {
      setUnreadMessages(prev => prev + 1);
    }
  }

  function handleJoinRoom(event) {
    console.log("LOGGER - Socket Joined Room: ", event);
    if (!event.error) {
      setRoomChats(event.data?.roomHistory);
    }
  }

  const showChatboxRef = useRef(showChatbox);

  // Keep the ref updated with the latest value of showChatbox. Need this as handleMessageReceived is not capturing the latest value of the showChatbox state
  useEffect(() => {
    showChatboxRef.current = showChatbox;

    if (showChatbox) setUnreadMessages(0);
  }, [showChatbox]);

  useEffect(() => {
    socket.emit("joinRoom", {
      roomId: roomId,
    });
    socket.on("roomMessage", handleMessageReceived);
    socket.on("joinRoomResponse", handleJoinRoom);

    return () => {
      socket.off("roomMessage", handleMessageReceived);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
  }, [roomId]);

  return (
    <S.ChatContainer>
      {showChatbox && <Chatbox roomChats={roomChats} roomId={roomId} poolData={poolData} closeChatbox={closeChatbox} />}
      <S.ChatButton onClick={toggleChatbox}>
        <MessagesIcon />
        {unreadMessages > 0 && <S.Badge>{unreadMessages}</S.Badge>}
      </S.ChatButton>
    </S.ChatContainer>
  );
};

export default Chat;
