import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import PromotedTournamentCard from "../../Tournament/PromotedTournamentCard";
import TournamentCard from "../../Tournament/TournamentCard";
import NoTournamentFound from "../../Tournament/NoTournamentFound";
import { useGetSecHeaders } from "../../../../hooks/SecurityHeaders";
import Loader from "../../Loader";
import { breakpoints } from "../../../../themes";
import TournamentListLoader from "../../../LoadingSkeleton/TournamentListLoader";
import { StreamModal } from "../../Tournament/StreamModal";

const TournamentListContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  min-height: 325px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${breakpoints.up(breakpoints.tablet)} {
    display: grid;
    grid-template-columns: ${props =>
      !props.isLoading && props.length >= 1 ? "repeat(auto-fill, minmax(300px, 1fr))" : "1fr"};
    align-self: center;
    justify-self: center;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContainerText = styled.div`
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StreamBtn = styled.button`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #be7eff;
  background-image: url(${props => (props.bg ? props.bg : "https://placehold.co/75x75")});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 999;
  &:hover {
    cursor: pointer;
  }
`;

const LiveIndicator = styled.div`
  position: absolute;
  bottom: -5px;
  left: -5px;
  background-color: #eb0400;
  border: 2px solid #201366;
  width: 38px;
  height: 20px;
  padding: 0;
  gap: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0;
  }
`;

// activeFilter here is only being used to order upcoming tournaments in the opposite order
export default function TournamentList({
  tournamentList,
  isLoading,
  showFinishedTournaments = true,
  activeFilter = "All",
  streamerData = [],
  isFetching = false,
}) {
  const headers = useGetSecHeaders();
  const tournamentLength = tournamentList.length;

  const [showStreamModal, setShowStreamModal] = useState(false);
  const toggleStreamModal = () => {
    setShowStreamModal(!showStreamModal);
  };

  if (isLoading) return <TournamentListLoader />;

  const TournamentsListView = () => {
    let tl = [...tournamentList];
    // Tournament list on main lobby page
    return !isLoading && !isFetching && tournamentList.length < 1 ? (
      <NoTournamentFound />
    ) : (
      tl.map(item => {
        const {
          name,
          description,
          endDate,
          startDate,
          sponsored,
          poolPrize,
          poolStatus,
          isPaid,
          id,
          winner,
          tokenName,
          players,
          entrance,
          leaderboard,
          tokenImage,
          tokenSymbol,
          assets: { poolSponsored, poolThumbnail, tournCardLogo },
          entranceTokenImage,
          entranceTokenSymbol,
          joinMode,
          game,
          winnersPrize,
          maxPlayers,
          organizerInfo,
        } = item;

        const formattedCurr = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(poolPrize);
        const formattedStartDate = moment(startDate).format("MMM DD, h:mm A");
        if (!showFinishedTournaments && (poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"))
          return null;
        return sponsored ? (
          <PromotedTournamentCard
            key={id}
            image={poolSponsored}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolPrize={poolPrize}
            poolName={name}
            description={description}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            endDate={endDate}
            winner={winner}
            tokenName={tokenName}
            players={players}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            poolStatus={poolStatus}
            headers={headers}
            tokenImage={tokenImage}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="promoted-tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
            organizerInfo={organizerInfo}
            streamStatus={streamerData?.find(streamer => streamer.poolId === id)}
          />
        ) : (
          <TournamentCard
            key={id}
            image={poolThumbnail}
            startDateTime={formattedStartDate}
            price={formattedCurr}
            poolName={name}
            description={description}
            sponsored={sponsored}
            isPaid={isPaid}
            startDate={startDate}
            endDate={endDate}
            winner={winner}
            tokenName={tokenName}
            players={players}
            playing={poolStatus === "RUNNING"}
            isFinished={poolStatus === "FINISHED_COLLECTING_DATA" || poolStatus === "FINISHED"}
            entrance={entrance}
            leaderboard={leaderboard}
            poolId={id}
            tokenImage={tokenImage}
            poolStatus={poolStatus}
            headers={headers}
            poolPrize={poolPrize}
            tokenSymbol={tokenSymbol}
            unformattedStartDate={startDate}
            entranceTokenImage={entranceTokenImage}
            entranceTokenSymbol={entranceTokenSymbol}
            length={tournamentLength}
            joinMode={joinMode}
            data-testid="tournament-card"
            gameId={game}
            winnerPrize={winnersPrize}
            maxPlayers={maxPlayers}
            tournCardLogo={tournCardLogo}
            organizerInfo={organizerInfo}
            streamStatus={streamerData?.find(streamer => streamer.poolId === id)}
          />
        );
      })
    );
  };

  return (
    <>
      <TournamentListContainer length={tournamentLength}>
        <TournamentsListView />
      </TournamentListContainer>

      {streamerData?.length >= 1 && (
        <div style={{ position: "absolute", right: "40px", bottom: "40px" }}>
          <StreamBtn onClick={toggleStreamModal} bg={streamerData?.[0]?.mainThumbnailUrl}>
            <LiveIndicator>
              <span>LIVE</span>
            </LiveIndicator>
          </StreamBtn>
          <StreamModal showModal={showStreamModal} toggleModal={toggleStreamModal} channelList={streamerData} />
        </div>
      )}
    </>
  );
}
