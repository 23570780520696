import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TournamentList from "./List";
import { useTournamentListQuery } from "../../../../hooks/TournamentsList";
import { Button } from "../../common/Button";
import { breakpoints } from "../../../../themes";
import useCallAxios from "../../../../hooks/useAxiosCall";
import { ENDPOINT } from "../../../../constants/endpoints";
import { useLocation, useHistory } from "react-router-dom";

const SectionContainer = styled.div`
  width: 100%;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const TopMenu = styled.div`
  padding-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${breakpoints.down(breakpoints.tablet)} {
    width: 100%;
  }
`;

const FilterWrapper = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
  padding-bottom: 23px;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  height: 40px;
  padding: 0 30px;
  border-radius: 30px;
  border: none;
  background: ${props =>
    props.isActive === true ? "linear-gradient(141.97deg, #1dc5ec -117.24%, #aa57ff 92.36%)" : "#3f2faa"};
  color: #ffffff;
  font-size: 1em;
  transition: all 0.3s ease-in-out;
  & div {
    margin-left: 10px;
    margin-right: 10px;
  }
  &:hover {
    margin-top: -2px;
    margin-bottom: 2px;
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(26, 14, 86, 1);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:disabled {
    cursor: not-allowed;
    color: #ffffffcc;
  }
  cursor: pointer;
`;

const PoolTournamentList = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location?.search);

  const [filterArray, setFilterArray] = useState(["Upcoming", "Playing", "Finished", "All"]); // Array with all the possible states
  const [activeFilter, setActiveFilter] = useState(queryParams.get("tournaments") || filterArray[0]);
  const [showFinishedTournaments, setShowFinishedTournaments] = useState(true);
  const [tournamentListState, setTournamentListState] = useState([]);
  const [tournamentStreaming, setTournamentStreaming] = useState([]);

  const { callAxiosFunc } = useCallAxios();

  const onClickFilter = item => {
    setTournamentListState([]);
    setActiveFilter(item);

    queryParams.set("tournaments", item);
    history.push({
      pathname: history.location.pathname,
      search: queryParams.toString(),
    });
  };

  // const { tournamentList, isLoading } = useTournamentList(activeFilter, !showFinishedTournaments);
  const { tournamentList, isLoading, isFetching } = useTournamentListQuery(activeFilter);

  const getStreams = async ({ poolId }) => {
    const response = await callAxiosFunc({
      method: "GET",
      url: `${ENDPOINT.GET_STREAM}?poolsId=${poolId}`,
      headers: JSON.stringify({ accept: "*/*" }),
    });
    if (response.err) {
      console.log(response.err);
    } else {
      setTournamentStreaming(response?.data?.data);
    }
  };

  useEffect(() => {
    if (tournamentList) {
      setTournamentListState(tournamentList);
      const extractedPoolIds = tournamentList?.map(pool => pool?.id)?.join(",");
      getStreams({ poolId: extractedPoolIds });
    }
  }, [tournamentList]);

  return (
    <SectionContainer>
      <TopMenu>
        <FilterWrapper>
          {filterArray.map(item => (
            <CustomButton
              disabled={isLoading}
              key={item}
              isActive={activeFilter === item}
              onClick={() => {
                if (activeFilter === item) return;
                onClickFilter(item);
              }}
            >
              {item}
            </CustomButton>
          ))}
        </FilterWrapper>
      </TopMenu>
      <TournamentList
        tournamentList={tournamentListState}
        isLoading={isLoading}
        isFetching={isFetching}
        showFinishedTournaments={showFinishedTournaments}
        activeFilter={activeFilter}
        streamerData={tournamentStreaming}
      />
    </SectionContainer>
  );
};

export default PoolTournamentList;
